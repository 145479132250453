import React from 'react'

import './Mezzi_style.scss';
import './Mezzi_responsive.scss';

import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';

import SliderRealizzazioni from '../../components/Slider/SliderRealizzazioni';


function Mezzi() {

  function importAll(r) {
    return r.keys().map(r);
  }
  
  const Images = importAll(require.context('../../images/Mezzi/', false, /\.(jpg|png|jpe?g|svg)$/));

  const Settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    rtl: false,
  };

  return (
    <div className='Mezzi'>
        <Navbar/>
        <div className="Content">
          <h1> PARCO MEZZI </h1>
          <p>
            Disponiamo di ampio parco mezzi che ci consente di eseguire tutte le lavorazioni in piena autonomia.
          </p>
          <SliderRealizzazioni images={Images} settings={Settings}/>
        </div>
        <Footer/>
    </div>
  )
}

export default Mezzi