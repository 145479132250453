import React, {useEffect} from 'react'

import './Tecnologie_style.scss';
import './Tecnologie_responsive.scss';

import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';

import DEPURAZIONE from '../../images/Tecnologie/FITODEPURAZIONE.jpg';
import MURATURA from '../../images/Tecnologie/MURATURA-ARMATA.jpg';
import PALIFICATE from '../../images/Tecnologie/PALIFICATE-IN-LEGNO.jpg';
import SOLAI from '../../images/Tecnologie/SOLAI-IN-LEGNO.jpg';
import TERRE from '../../images/Tecnologie/TERRE-ARMATE-E-GABBIONI.jpg';

function Tecnologie() {

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

    useEffect(()=>{
        var cards = document.getElementsByClassName("Card");
        let i = 0;
        Array.from(cards).forEach(function(e) {
            sleep(200*i).then(() => {
                e.style.display = "inline-flex";
            });
            i++;
        })

    })


  return (
    <div className='Tecnologie'>
        <Navbar/>
        <div className="Contents">

            <a href='/fitodepurazione'>
                <span className="Card Card-0"> 
                    <h1> FITODEPURAZIONE </h1>
                    <img src={DEPURAZIONE} alt="Fitodepurazione" />
                </span>
            </a>

            <a href='muratura-armata'>
                <span className="Card Card-1"> 
                    <h1> MURATURA </h1>
                    <img src={MURATURA} alt="Muratura armata" />
                </span>
            </a>

            <a href='palificate-in-legno'>
                <span className="Card Card-2">
                    <h1> PALIFICATE IN LEGNO </h1> 
                    <img src={PALIFICATE} alt="Palificate in legno" />
                </span>
            </a>

            <a href='solai-in-legno'>
                <span className="Card Card-3">
                    <h1> SOLAI IN LEGNO </h1>
                    <img src={SOLAI} alt="Solai in legno" />
                </span>
            </a>

            <a href='terre-armate'>
                <span className="Card Card-4">
                    <h1> TERRE ARMATE E GABBIONI </h1> 
                    <img src={TERRE} alt="Terre armate e gabbioni" />
                </span>
            </a>

        </div>
        <Footer/>
    </div>
  )
}

export default Tecnologie