import React from 'react'
import '../Realizzazioni_style.scss'

import SliderRealizzazioni from '../../../components/Slider/SliderRealizzazioni';
import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../../components/Footer/Footer';;

function Edilizia() {

    function importAll(r) {
        return r.keys().map(r);
      }
      
      const Images = importAll(require.context('../../../images/Edilizia/', false, /\.(jpg|png|jpe?g|svg)$/));
    
      const Settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        rtl: false,
      };
    
  return (
    <div>
    <Navbar/>
    <div className="Content">
        <h1> EDILIZIA CIVILE </h1>
        <p>
            
            Costruiamo edifici civili, industriali, artigianali utilizzando sistemi costruttivi diversificati secondo le esigenze del committente (cemento armato, muratura portante, legno,prefabbricati ecc).
            <br /><br />
            Operiamo nel campo delle ristrutturazioni e siamo specializzati nella costruzione di solai e tetti in legno.
            <br /><br />
            Il nostro punto di forza sta nel mantenerci costantemente aggiornati sulle nuove tecnologie costruttive con particolare attenzione al campo della bioedilizia.
            <br /><br />
            Siamo in grado di offrire assistenza tecnica sia in fase di progettazione che durante l’esecuzione dei lavori

        </p>
        <SliderRealizzazioni images={Images} settings={Settings}/>
        </div>
    <Footer/>
</div>
  )
}

export default Edilizia