import React from 'react'

import './Referenze_style.scss';
import './Referenze_responsive.scss';

import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';

import ComuneRocchettaVara from '../../images/Referenze/1-Comune-di-Rocchetta-Vara.jpg'
import ComuneAvegno from '../../images/Referenze/2-Comune-di-Avegno.jpg'
import ComuneBardi from '../../images/Referenze/3-Comune-di-Bardi.jpg'
import ComuneBorghetto from '../../images/Referenze/4-Comune-di-Borghetto.jpg'
import ComuneBorzonasca from '../../images/Referenze/5-Comune-di-Borzonasca.jpg'
import ComuneBrugnato from '../../images/Referenze/6-Comune-di-Brugnato.jpg'
import ComuneCalice from '../../images/Referenze/7-Comune-di-Calice-al.jpg'
import ComuneLavagna from '../../images/Referenze/8-Comune-di-Lavagna.jpg'
import ComuneMaissana from '../../images/Referenze/9-Comune-di-Maissana.jpg'
import ComuneMezzanego from '../../images/Referenze/10-Comune-di-Mezzanego.jpg'
import ComunePignone from '../../images/Referenze/11-Comune-di-Pignone.jpg'
import ComuneSesta from '../../images/Referenze/12-Comune-di-Sesta.jpg'
import ComuneVarese from '../../images/Referenze/13-Comune-di-Varese.jpg'
import ProvinciaSpezia from '../../images/Referenze/14-Provincia-di-Spezia.jpg'
import RegioneLiguria from '../../images/Referenze/15-Regione-Liguria.jpg'
import Enav from '../../images/Referenze/16-ENAV.jpg'
import Acam from '../../images/Referenze/17-Acam.jpg'
import ComuneCasarza from '../../images/Referenze/18-Comune-di-Casarza-Ligure.jpg'
import ComuneCastiglione from '../../images/Referenze/19-Comune-Castiglione-Chiavarese.jpg'
import ComuneSpezia from '../../images/Referenze/20-Comune-della-Spezia.jpg'
import ComuneCamogli from '../../images/Referenze/21-Comune-di-Camogli.jpg'
import ComuneCogorno from '../../images/Referenze/22-Comune-di-Cogorno.jpg'
import ComuneMoneglia from '../../images/Referenze/23-Comune-di-Moneglia.jpg'
import ComuneMonterosso from '../../images/Referenze/24-Comune-di-Monterosso-Al-Mare.jpg'
import ComuneNe from '../../images/Referenze/25-Comune-di-Ne.jpg'
import ComuneVernazza from '../../images/Referenze/26-Comune-di-Vernazza.jpg'
import ComuneZignago from '../../images/Referenze/27-Comune-di-Zignago.jpg'
import Cosme from '../../images/Referenze/28-Cosme.jpg'
import ComuneDeiva from '../../images/Referenze/29-Comune-di-Deiva-marina.jpg'
import Iren from '../../images/Referenze/30-Iren.jpg'
import Ireti from '../../images/Referenze/31-Ireti.jpg'

function Referenze() {


  return (
    <div className='Referenze'>
        <Navbar/>
        <div className="Contents">
            <h1> REFERENZE </h1>
            <div className='container'>
              <div> 
                <img src={ComuneRocchettaVara} alt="Comune Rocchetta Vara" /> 
                <p> Comune di Rocchetta Vara (SP) </p>
              </div>

              <div> 
                <img src={ComuneAvegno} alt="Comune Avegno" /> 
                <p> Comune di Avegno (GE) </p>
              </div>

              <div> 
                <img src={ComuneBardi} alt="Comune Bardi" /> 
                <p> Comune di Bardi (PR) </p>
              </div>

              <div> 
                <img src={ComuneBorghetto} alt="Comune Borghetto" /> 
                <p> Comune di Borghetto (SP) </p>
              </div>

              <div> 
                <img src={ComuneBorzonasca} alt="Comune Borzonasca" /> 
                <p> Comune di Borzonasca (GE) </p>
              </div>

              <div> 
                <img src={ComuneBrugnato} alt="Comune Brugnato" /> 
                <p> Comune di Brugnato (SP) </p>
              </div>

              <div> 
                <img src={ComuneCalice} alt="Comune Calice" /> 
                <p> Comune di Calice (SP) </p>
              </div>

              <div> 
                <img src={ComuneLavagna} alt="Comune Lavagna" /> 
                <p> Comune di Lavagna (GE) </p>
              </div>

              <div> 
                <img src={ComuneMaissana} alt="Comune Maissana" /> 
                <p> Comune di Maissana (SP) </p>
              </div>

              <div> 
                <img src={ComuneMezzanego} alt="Comune Mezzanego" /> 
                <p> Comune di Mezzanego (GE) </p>
              </div>

              <div> 
                <img src={ComunePignone} alt="Comune Pignone" /> 
                <p> Comune di Pignone (SP) </p>
              </div>

              <div> 
                <img src={ComuneSesta} alt="Comune Sesta" /> 
                <p> Comune di Sesta Godano (SP) </p>
              </div>

              <div> 
                <img src={ComuneVarese} alt="Comune Varese" /> 
                <p> Comune di Varese Ligure (SP) </p>
              </div>

              <div> 
                <img src={ProvinciaSpezia} alt="Provincia Spezia" /> 
                <p> Provincia di La Spezia </p>
              </div>

              <div> 
                <img src={RegioneLiguria} alt="Regione Liguria" /> 
                <p> Regione Liguria </p>
              </div>

              <div> 
                <img src={Enav} alt="Enav" /> 
                <p> ENAV </p>
              </div>

              <div> 
                <img src={Acam} alt="Acam" /> 
                <p> ACAM </p>
              </div>

              <div> 
                <img src={ComuneCasarza} alt="Comune Casarza" /> 
                <p> Comune di Casarza Ligure (GE) </p>
              </div>

              <div> 
                <img src={ComuneCastiglione} alt="Comune Castiglione" /> 
                <p> Comune di Castiglione Chiavarese (GE) </p>
              </div>

              <div> 
                <img src={ComuneSpezia} alt="Comune Spezia" /> 
                <p> Comune di Spezia (SP) </p>
              </div>

              <div> 
                <img src={ComuneCamogli} alt="Comune Camogli" /> 
                <p> Comune di Camogli (GE) </p>
              </div>

              <div> 
                <img src={ComuneCogorno} alt="Comune Cogorno" /> 
                <p> Comune di Cogorno (GE) </p>
              </div>

              <div> 
                <img src={ComuneMoneglia} alt="Comune Moneglia" /> 
                <p> Comune di Moneglia (GE) </p>
              </div>

              <div> 
                <img src={ComuneMonterosso} alt="Comune Monterosso" /> 
                <p> Comune di Monterosso (SP) </p>
              </div>

              <div> 
                <img src={ComuneNe} alt="Comune Ne" /> 
                <p> Comune di Ne (GE) </p>
              </div>

              <div> 
                <img src={ComuneVernazza} alt="Comune Vernazza" /> 
                <p> Comune di Vernazza (SP) </p>
              </div>

              <div> 
                <img src={ComuneZignago} alt="Comune Zignago" /> 
                <p> Comune di Zignago (SP) </p>
              </div>

              <div> 
                <img src={Cosme} alt="Cosme" /> 
                <p> COSME </p>
              </div>

              <div> 
                <img src={ComuneDeiva} alt="Comune Deiva" /> 
                <p> Comune di Deiva (GE) </p>
              </div>

              <div> 
                <img src={Iren} alt="Iren" /> 
                <p> IREN </p>
              </div>

              <div> 
                <img src={Ireti} alt="Ireti" /> 
                <p> IRETI </p>
              </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Referenze