import React from 'react'
import '../Realizzazioni_style.scss';

import SliderRealizzazioni from '../../../components/Slider/SliderRealizzazioni';
import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../../components/Footer/Footer';

function Piscine() {


    function importAll(r) {
        return r.keys().map(r);
      }
      
      const Images = importAll(require.context('../../../images/Piscine/', false, /\.(jpg|png|jpe?g|svg)$/));
    
      const Settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        rtl: false,
      };
    
  return (
    <div>
    <Navbar/>
    <div className="Content">
        <h1> PISCINE, PARCHI, CAMPI </h1>
        <p>
            Eseguiamo lavori di sistemazione di aree verdi, campi da gioco, giardini e piscine.
            <br /> <br />
            Collaboriamo con ditte specializzate, leader nel settore, per la posa in opera di campi in erba sintetica e pavimentazioni antitrauma per parchi gioco
        </p>
        <SliderRealizzazioni images={Images} settings={Settings}/>
        </div>
    <Footer/>
</div>
  )
}

export default Piscine