import React from 'react'
import '../Tecniche_style.scss';

import SliderRealizzazioni from '../../../components/Slider/SliderRealizzazioni';
import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../../components/Footer/Footer';

function Fitodepurazione() {

    function importAll(r) {
        return r.keys().map(r);
      }
      
      const Images = importAll(require.context('../../../images/Fitodepurazione/', false, /\.(jpg|png|jpe?g|svg)$/));
    
      const Settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        rtl: false,
      };
    


    return (
        <div>
            <Navbar/>
            <div className="Contents">
                <h1> FITODEUPURAZIONE </h1>
                <SliderRealizzazioni images={Images} settings={Settings}/>
                </div>
            <Footer/>
        </div>
    )
}

export default Fitodepurazione