import React from 'react'
import '../Realizzazioni_style.scss';

import SliderRealizzazioni from '../../../components/Slider/SliderRealizzazioni';
import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../../components/Footer/Footer';

function Cemento() {

    function importAll(r) {
        return r.keys().map(r);
      }
      
      const Images = importAll(require.context('../../../images/Scavi/', false, /\.(jpg|png|jpe?g|svg)$/));
    
      const Settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        rtl: false,
      };
    
    return (
        <div>
        <Navbar/>
        <div className="Content">
            <h1> CEMENTO ARMATO </h1>
            <p>
                Siamo specializzati nell’esecuzione di opere in cemento armato quali:
                <br /><br />
                ● strutture intelaiate costituite da travi e pilastri
                <br /><br />
                ● muri di sostegno di qualsiasi tipo e dimensione
                <br /><br />
                ● box interrati
                <br /><br />
                ● strutture complesse a sezioni variabili o con parti curvilinee
                <br /><br />
                ● fondazioni speciali su micropali
            </p>
            <SliderRealizzazioni images={Images} settings={Settings}/>
            </div>
        <Footer/>
    </div>
    )
}

export default Cemento