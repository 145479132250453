import React, {Component} from 'react'
import Slider from "react-slick";

import "./Slider_style.scss";
import "./Slider_responsive.scss";
import arrow from '../../images/Slider/arrow.png'

function Arrow(props) {
  let className = props.type === "next" ? "nextArrow" : "prevArrow";
  className += " ArrowSlider";
  return (
    <span className={className} onClick={props.onClick}>
      <img src={arrow} alt="a" />
    </span>
  );
}

export default class Slider_Realizzazioni extends Component {
  
  render() {
    const settings = {...this.props.settings, nextArrow:<Arrow type="next" />, prevArrow:<Arrow type="prev" />};

    const Images = this.props.images;
    
    return (
      <div className='slider'>
        <Slider {...settings}>
          { 
              Images.map(e => 
                <div className='card' key={e}> 
                  <img src={e} alt="2" />
                </div>
          )} 
        </Slider>
      </div>
    );
  }
}