import { Routes, Route } from "react-router-dom";
import Home from "./views/home/Home";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import './App.css'
import Chisiamo from "./views/chisiamo/Chisiamo";
import Contributi from "./views/contributi/Contributi";

import Mezzi from "./views/mezzi/Mezzi";
import Referenze from "./views/referenze/Referenze";
import Tecnologie from "./views/tecnologie/Tecnologie";

import Acquedotti from "./views/realizzazioni/acquedotti/Acquedotti";
import Cemento from "./views/realizzazioni/cemento/Cemento";
import Edilizia from "./views/realizzazioni/edilizia/Edilizia";
import Stradali from "./views/realizzazioni/stradali/Stradali";
import Idrauliche from "./views/realizzazioni/idrauliche/Idrauliche";
import Piscine from "./views/realizzazioni/piscine/Piscine";
import Naturalistica from "./views/realizzazioni/naturalistica/Naturalistica";
import Scavi from "./views/realizzazioni/scavi/Scavi";

import Contatti from "./views/contatti/Contatti";

import Fitodepurazione from "./views/tecniche/fitodepurazione/Fitodepurazione";
import Muratura from "./views/tecniche/muratura/Muratura";
import Palificate from "./views/tecniche/palificate/Palificate";
import Solai from "./views/tecniche/solai/Solai";
import Terrearmate from "./views/tecniche/terrearmate/Terrearmate";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="chi-siamo" element={<Chisiamo/>} />
        <Route path="contributi-pubblici" element={<Contributi/>} />

        <Route path="parco-mezzi" element={<Mezzi/>} />
        <Route path="referenze" element={<Referenze/>} />
        <Route path="tecnologie-costruttive" element={<Tecnologie/>} />

        <Route path="acquedotti-fognature" element={<Acquedotti/>} />
        <Route path="cemento-armato" element={<Cemento/>} />
        <Route path="edilizia-civile" element={<Edilizia/>} />
        <Route path="lavori-stradali" element={<Stradali/>} />
        <Route path="opere-idrauliche" element={<Idrauliche/>} />
        <Route path="piscine-parchi-campi" element={<Piscine/>} />
        <Route path="ingegneria-naturalistica" element={<Naturalistica/>} />
        <Route path="scavi-demolizioni" element={<Scavi/>} />

        <Route path="fitodepurazione" element={<Fitodepurazione/>} />
        <Route path="muratura-armata" element={<Muratura/>} />
        <Route path="palificate-in-legno" element={<Palificate/>} />
        <Route path="solai-in-legno" element={<Solai/>} />
        <Route path="terre-armate" element={<Terrearmate/>} />

        <Route path="contatti" element={<Contatti/>} />

      </Routes>
    </div>
  );
}

export default App;
