import React from 'react'

import './Footer_style.scss';
import './Footer_responsive.scss';

import FACEBOOK from '../../images/Footer/facebook.png';
import INSTAGRAM from '../../images/Footer/instagram.png';

function Footer() {
  return (
    <div className='Footer'>

        <div className="Contacts">
            <h1> SILVANO COSTRUZIONI S.R.L </h1>

            <ul>
                <li> <p> Via pivazzano 1C </p> </li>
                <li> <p> Varese Ligure 19028 SP </p> </li>

                <li> <p> info@silvanocostruzioni.it </p> </li>
                <li> <p> ufficio.tecnico@silvanocostruzioni.it </p> </li>
                <li> <p> ufficio.amministrazione@silvanocostruzioni.it </p> </li>

                <li> <p> CF/PI 01331300119 </p> </li>
                <li> <p> REA SP119883 - c.s.100.000€ i.v.</p> </li>

                <li> <p> +39 328 1149366 </p> </li>
                <li> <p> +39 333 2785934 </p> </li>
                <li> <p> +39 0187 1859303 </p> </li>
            </ul>
        </div>

        <div className='Sitemap'>

            <h1> SITE MAP </h1>

            <ul>
            <a href='chi-siamo'> <li> Chi siamo </li> </a>
                <a href='/#Certificati'> <li> Certificazioni </li> </a>
                <a href='/#Lavori'> <li> Realizzazioni </li> </a>
                <a href='tecnologie-costruttive'> <li> Tecnologie costruttive </li> </a>
                <a href='parco-mezzi'> <li> Parco mezzi </li> </a>
                <a href='referenze'> <li> Referenze </li> </a>
                <a href='contributi-pubblici'> <li> Contributi pubblici </li> </a>
                <a href='contatti'> <li> Contatti </li> </a>

            </ul>

        </div>

        <div className='Social'>
            <h1> SOCIAL </h1>

            <ul>
                <li>  <a href='https://it-it.facebook.com/silvanocostruzionisrl/'> '<img src={FACEBOOK} alt="facebook" /> </a> </li>
                <li>  <a href='https://www.instagram.com/silvano.costruzioni.srl/'> <img src={INSTAGRAM} alt="facebook" /> </a> </li>
            </ul>

        </div>



    </div>
    )
}

export default Footer