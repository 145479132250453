import React from 'react'
import '../Realizzazioni_style.scss';

import SliderRealizzazioni from '../../../components/Slider/SliderRealizzazioni';
import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../../components/Footer/Footer';

function Acquedotti() {

    function importAll(r) {
        return r.keys().map(r);
      }
      
      const Images = importAll(require.context('../../../images/Acquedotti/', false, /\.(jpg|png|jpe?g|svg)$/));
    
      const Settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        rtl: false,
      };
    


    return (
        <div>
            <Navbar/>
            <div className="Content">
                <h1> ACQUEDOTTI E FOGNATURE </h1>
                <p>
                    Eseguiamo lavori per la costruzione di reti fognarie, acquedotti e canalizzazioni di qualsiasi tipo.Disponiamo di macchina per saldatura tubi in PEAD.
                    <br /><br />
                    Siamo specializzati nella costruzione di impianti fognari che non ricadono in pubblica fognatura per i quali offriamo anche assistenza tecnica in fase di progettazione per l’autorizzazione Comunale previo nulla-osta di Arpal.
                    Tali impianti sono costituiti essenzialmente da un trattamento primario (fossa imhoff) e da un trattamento secondario (vasca di fitodepurazione).
                    <br /><br />
                    Nel caso in cui non si disponesse dello spazio necessario per la fitodepurazione si possono installare dei filtri percolatori aerobici o anaerobici a seconda della tiplogia dell’impianto.
                </p>
                <SliderRealizzazioni images={Images} settings={Settings}/>
                </div>
            <Footer/>
        </div>
    )
}

export default Acquedotti