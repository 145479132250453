import React from 'react'
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';

import './Contributi_responsive.scss';
import './Contributi_style.scss';

function Contributi() {
  return (
    <div className='Contributi'>
        <Navbar/>
        <div className="Content">
            <h1> CONTRIBUTI PUBBLICI LEGGE 124/2017 </h1>
            <p> 
                INFORMAZIONE SU AIUTI E CONTRIBUTI PUBBLICI LEGGE 124/2017 RICEVUTI NELL’ANNO 2020
                <br /><br />
                Si informa che la Società ha beneficiato dei seguenti aiuti di Stato
                <br /><br />
                Abbuono saldo IRAP 2019 per Euro 1.563,00 ex art. 24 D.L. n. 34/2020 (D.L. Rilancio)
                Abbuono primo acconto IRAP 2020 per Euro 3.015,50 ex art. 24 D.L. n. 34/2020 (D.L. Rilancio)
                <br /><br />
                Le garanzie pubbliche su finanziamenti ricevuti sono desumibili dal Registro Nazionale degli Aiuti di Stato di cui all’art. 52 L. 234/2012”.
                INFORMAZIONE SU AIUTI E CONTRIBUTI PUBBLICI LEGGE 124/2017 RICEVUTI NELL’ANNO 2019
                <br /> <br />
                La societa’ ha ottenuto nel corso dell’anno 2019 l’ erogazione di un contributo di Euro 23.595,56 da parte di F.I.L.S.E. spa per P.O.R. FESR LIGURIA 2014-2020 ASSE 3 COMPETITIVITA’ DELLE IMPRESE AZIONE 3.1.1.
                L’ importo è stato incassato in data 26 luglio 2019.
                <br /><br />
                INFORMAZIONE SU AIUTI E CONTRIBUTI PUBBLICI LEGGE 124/2017 RICEVUTI NELL’ANNO 2018
                <br /><br />
                Ai sensi e per gli effetti dell’art. 1 comma 125 della legge 4 agosto 2017 n. 124 si fa presente che la società ha ottenuto nel corso dell’anno 2018 l’erogazione di una tranche di Euro 60.500,00 di contributo da parte di F.I.L.S.E. Spa a titolo di erogazione saldo aiuto in de minimis Az. 1.1.3-Ambiti S3-3.
                <br /> <br />
                L’importo è stato incassato in data 15 novembre 2018
            </p>
        </div>
        <Footer/>
    </div>
  )
}

export default Contributi