import React from 'react'
import '../Realizzazioni_style.scss';

import SliderRealizzazioni from '../../../components/Slider/SliderRealizzazioni';
import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../../components/Footer/Footer';

function Stradali() {

    function importAll(r) {
        return r.keys().map(r);
      }
      
      const Images = importAll(require.context('../../../images/Stradali/', false, /\.(jpg|png|jpe?g|svg)$/));
    
      const Settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        rtl: false,
      };
    
  return (
    <div>
    <Navbar/>
    <div className="Content">
        <h1> LAVORI STRADALI </h1>
        <p>
            Siamo attrezzati per eseguire ogni tipo di lavoro stradale, dalla costruzione di nuovi tracciati al ripristino di carreggiate in frana.
            <br /><br />
            Eseguiamo inoltre parcheggi, piazzali, pavimentazioni in pietra, autobloccanti, conglomerato bituminoso, marciapiedi, posa in opera di barriere stradali sia in acciaio che in legno.
            <br /><br />
            Abbiamo inoltre maturato una vasta esperienza in consolidamenti di strade interessate da movimenti franosi con tecniche di ingegneria naturalistica (vedi Terre Armate Ingegneria Naturalistica)
        </p>
        <SliderRealizzazioni images={Images} settings={Settings}/>
        </div>
    <Footer/>
</div>
  )
}

export default Stradali