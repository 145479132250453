import React from 'react';

import './Navbar_style.scss';
import './Navbar_responsive.scss';

import logo from '../../images/Navbar/logo.png';

function Navbar() {

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const dynamic_open = () => {
    var lines = document.getElementsByClassName("Line");
    var menu = document.getElementById("dynamic-menu");
    var list = menu.getElementsByTagName("li");

    if (menu.classList.contains("Open")) {

      lines[0].classList.remove("OpenLine1");
      lines[1].classList.remove("OpenLine3");
      lines[2].classList.remove("OpenLine2");

      menu.classList.remove("Open");
      menu.classList.add("Close");
      
      let i = 0;
      Array.from(list).forEach(function(e) {
        sleep(0*i).then(()=>{ e.classList.remove("dropdown_item-"+i);});
        i++;
      });

    } else {
      lines[0].classList.add("OpenLine1");
      lines[1].classList.add("OpenLine3");
      lines[2].classList.add("OpenLine2");

      menu.classList.remove("Close");
      menu.classList.add("Open");

      let i = 0;
      Array.from(list).forEach(function(e) {
        sleep(60*i).then(()=>{ e.classList.add("dropdown_item-"+i);});
        i++;
      });
    }

  }


  return (
    <div className="Navbar">
        <div className="Logo"> <a href='/'> <img src={logo} alt="logo" /> </a> </div>
        
        <div className="StaticMenu"> 
            <ul>
                <a href='chi-siamo'> <li> CHI SIAMO </li> </a>
                <a href='/#Certificati'> <li> CERTIFICAZIONI </li> </a>
                <a href='/#Lavori'> <li> REALIZZAZIONI </li> </a>
                <a href='tecnologie-costruttive'> <li> TECNOLOGIE COSTRUTTIVE </li> </a>
                <a href='parco-mezzi'> <li> PARCO MEZZI </li> </a>
                <a href='referenze'> <li> REFERENZE </li> </a>
                <a href='contributi-pubblici'> <li> CONTRIBUTI PUBBLICI</li> </a>
                <a href='contatti'> <li> CONTATTI </li> </a>

            </ul>
        </div>



      <div id="dynamic-menu" className="DynamicMenu">
        <div className="ButtonMenu" onClick={dynamic_open}>
          <div className="Line"></div>
          <div className="Line"></div>
          <div className="Line"></div>
        </div>

        <div className="Menu"> 
            <ul>
                <a href='chi-siamo'> <li> CHI SIAMO </li> </a>
                <a href='/#Certificati'> <li> CERTIFICAZIONI </li> </a>
                <a href='/#Lavori'> <li> REALIZZAZIONI </li> </a>
                <a href='tecnologie-costruttive'> <li> TECNOLOGIE COSTRUTTIVE </li> </a>
                <a href='parco-mezzi'> <li> PARCO MEZZI </li> </a>
                <a href='referenze'> <li> REFERENZE </li> </a>
                <a href='contributi-pubblici'> <li> CONTRIBUTI PUBBLICI</li> </a>
                <a href='contatti'> <li> CONTATTI </li> </a>
            </ul>
        
        </div>


      </div>

    </div>
  )
}

export default Navbar