import React, {useEffect} from 'react';

import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';

import './Home_responsive.scss';
import './Home_style.scss';

import ARROW from '../../images/Home/arrow.png';

import CEMENTO from '../../images/Home/Lavori/CEMENTO-ARMATO.jpg';
import ACQUEDOTTO from '../../images/Home/Lavori/ACQUEDOTTI-E-FOGNATURE.jpg';
import NATURALISTICA from '../../images/Home/Lavori/INGEGNERIA-NATURALISTICA.jpg';
import PISCINA from '../../images/Home/Lavori/PISCINE-PARCHI-CAMPI.jpg';
import SCAVI from '../../images/Home/Lavori/SCAVI-E-DEMOLIZIONI.jpg';
import EDILIZIA from '../../images/Home/Lavori/EDILIZIA-CIVILE.jpg';
import STRADALI from '../../images/Home/Lavori/LAVORI-STRADALI.jpg';
import IDRAULICHE from '../../images/Home/Lavori/OPERE-IDRAULICHE.jpg';

import SILVANO from '../../images/Home/Findmore/SILVANO.jpg';
import TECNOLOGIE from '../../images/Home/Findmore/TECNOLOGIE.jpg';
import MEZZI from '../../images/Home/Findmore/MEZZI.jpg';

import WHOAMI from '../../images/Home/whoami.jpg';

import SOA from '../../images/Home/Certificati/soa.png';
import ALBO from '../../images/Home/Certificati/albo.png';
import WHITELIST from '../../images/Home/Certificati/whitelist.png';
import ISO9001 from '../../images/Home/Certificati/iso9001.png';

import SOApdf from '../../images/Home/Certificati/SOA.pdf';
import ALBOpdf from '../../images/Home/Certificati/AlboNazionaleGestoriAmbientali.pdf';
import WHITELISTpdf from '../../images/Home/Certificati/WHITELIST.pdf';
import ISO9001pdf from '../../images/Home/Certificati/CertificazioneISO9001.pdf';

function Home() {
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const observercards = new IntersectionObserver(entries => {
    entries.forEach(entry => {
  
      if (entry.isIntersecting) {
        const lavori = document.querySelector('.Lavori');
        const objects = lavori.getElementsByTagName('span');
        let i= 0;
        Array.from(objects).forEach(function(e) {
          sleep(150*i).then(()=>{ 
            e.classList.add("active");
            e.getElementsByTagName("h1")[0].classList.add("textactive");
          });
          i++;
        });
      return;
      }
  
    });    

  });

  const observewhoami = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const whoami = document.getElementById('first-whoami');
        const objects = document.querySelector('.Container').getElementsByTagName('div');

        whoami.classList.add('active-opacity');

        let i= 0;
        Array.from(objects).forEach(function(e) {
          sleep(150*i).then(()=>{ 
            e.classList.add("active");
          });
          i++;
        });
        
      }
    })
  })


useEffect(() => {
  observercards.observe(document.getElementById('first-lavori'));
  observewhoami.observe(document.getElementById('first-whoami'));
});
  
  return (
    <div className='Home'>
        <Navbar />
        
        <section className='Presentation'> 
          <div className="arrow"> <img src={ARROW} alt="arrow" /></div>
        </section>

          <section className='Lavori' id='Lavori' >

            <a href='cemento-armato'>
              <span> 
                <img id="first-lavori" src={CEMENTO} alt="Cemento armato" />
                <h1> CEMENTO ARMATO </h1> 
              </span>
            </a>

            <a href='acquedotti-fognature'>
              <span> 
                <img src={ACQUEDOTTO} alt="Acquedotti e fognature" /> 
                <h1> ACQUEDOTTI <br/> FOGNATURE </h1>
              </span>
            </a>


            <a href='ingegneria-naturalistica'>
              <span>
                <img src={NATURALISTICA} alt="Ingegneria naturalistica" /> 
                <h1> INGEGNERIA NATURALISTICA </h1>
              </span>
            </a>

            <a href='piscine-parchi-campi'>
              <span> 
                <img src={PISCINA} alt="Piscina parchi e campi" /> 
                <h1> PISCINE PARCHI CAMPI </h1>
              </span>
            </a>

            <a href='scavi-demolizioni'>
              <span>
                <img src={SCAVI} alt="Scavi e demolizioni" /> 
                <h1> SCAVI E DEMOLIZIONI </h1>
              </span>
            </a>

            <a href='edilizia-civile'>
              <span> 
                <img src={EDILIZIA} alt="Edilizia civile" /> 
                <h1> EDILIZIA CIVILE </h1>
              </span>
            </a>

            <a href='lavori-stradali'>
              <span> 
                <img src={STRADALI} alt="Lavori stradali" /> 
                <h1> LAVORI STRADALI </h1>
              </span>
            </a>

            <a href='opere-idrauliche'>
              <span> 
                <img src={IDRAULICHE} alt="Opere idrauliche" /> 
                <h1> OPERE IDRAULICHE </h1>
              </span>
            </a>

          </section>

        <section className='Whoami' id="first-whoami"> 

          <img id="whoami" src={WHOAMI} alt="operazione notturna" />

          <div className="Container">
            <a href='/tecnologie-costruttive'>
              <div>
                <img src={TECNOLOGIE} alt="Tecnologie di costruzioni" />
                <p className='Title'> TECNOLOGIE COSTRUTTIVE </p>
                <p className='Description'> UTILIZZIAMO DIVERSE TECNOLOGIE COSTRUTTIVE PER PORTARE A TERMINE I NOSTRI LAVORI </p> 
                <span className='Findmore'> 
                  <p> SCOPRI DI PIU </p> 
                  <p className='arrow'> &#8250; </p>
                </span>
              </div>
            </a>

            <a href='/parco-mezzi'>
              <div>
                <img src={MEZZI} alt="Parco mezzi" />
                <p className='Title'> PARCO MEZZI </p>
                <p className="Description"> DISPONIAMO DI UN AMPIO PARCO MEZZI CHE CI CONSENTE DI ESEGUIRE TUTTE LE LAVORAZIONI IN AUTONOMIA </p>
                <span className='Findmore'> 
                  <p> SCOPRI DI PIU </p> 
                  <p className='arrow'> &#8250; </p>
                </span>
              </div>
            </a>

            <a href='/chi-siamo'>
              <div>
                <img src={SILVANO} alt="Silvano costruzioni" />
                <p className='Title'> CHI SIAMO </p>
                <p className="Description"> SILVANO COSTRUZIONI NELL'IMMEDIATO DOPOGUERRA PER INIZIATIVA DI LUIGI SILVANO</p>
                <span className='Findmore'> 
                  <p> SCOPRI DI PIU </p> 
                  <p className='arrow'> &#8250; </p>
                </span>
              </div>
            </a>
          </div>
          
        </section>


      <section className='Certificati' id="Certificati"> 

          <h1> CERTIFICAZIONI </h1>

          <div className='Container'> 

            <div>
              <a href={SOApdf}> <img src={SOA} alt="Certificato soa" /> </a>
              <p> SOA </p>
            </div>

            <div>
              <a href={ALBOpdf}> <img src={ALBO} alt="Certificato Albo nazionale" /> </a>
              <p> Albo Nazionale Gestori Ambientali </p> 
            </div>
            
            <div>
               <a href={WHITELISTpdf}> <img src={WHITELIST} alt="Certificato whitelist" /> </a>
               <p> Iscrizione White list</p>
            </div>

            <div>
               <a href={ISO9001pdf}> <img src={ISO9001} alt="Certificato whitelist" /> </a>
               <p> Certificazione ISO9001</p>
            </div>

          </div>

      </section>

      <Footer/>

    </div>
  )
}

export default Home