import React from 'react'
import '../Realizzazioni_style.scss';

import SliderRealizzazioni from '../../../components/Slider/SliderRealizzazioni';
import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../../components/Footer/Footer';

function Naturalistica() {

    function importAll(r) {
        return r.keys().map(r);
      }
      
      const Images = importAll(require.context('../../../images/Naturalistica/', false, /\.(jpg|png|jpe?g|svg)$/));
    
      const Settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        rtl: false,
      };
    


    return (
        <div>
            <Navbar/>
            <div className="Content">
                <h1> TERRE ARMATE INGEGNERIA NATURALISTICA </h1>
                <p>
                    Negli ultimi anni la ditta si è specializzata prevalentemente negli interventi di prevenzione e gestione di disastri naturali ed emergenze in aree ad alto rischio idrogeologico.
                    <br /><br />
                    Grazie all’adeguata formazione ottenuta mediante la partecipazione a corsi specifici nel settore, all’impiego di personale altamente qualificato, ed all’investimento in nuove attrezzature l’impresa ha sviluppato nuove tecniche di intervento a limitato impatto ambientale che consentono sia la messa in sicurezza del territorio che la relativa prevenzione.
                    <br /><br />
                    L’impresa è in possesso di certificazione per la categoria SOA OG13 Interventi di Ingegneria Naturalistica. Tale categoria riguarda la costruzione, la manutenzione o la ristrutturazione di opere o lavori puntuali, e di opere o di lavori diffusi, necessari alla difesa del territorio ed al ripristino della compatibilità fra “sviluppo sostenibile” ed ecosistema, comprese tutte le opere ed i lavori necessari per attività botaniche e zoologiche.
                    <br /><br />
                    Comprende in via esemplificativa i processi di recupero naturalistico, botanico e faunistico, la conservazione ed il recupero del suolo utilizzato per cave e torbiere e dei bacini idrografici, l’eliminazione del dissesto idrogeologico per mezzo di piantumazione, le opere necessarie per la stabilità dei pendii, la riforestazione, i lavori di sistemazione agraria e le opere per la rivegetazione di scarpate stradali, ferroviarie, cave e discariche.
                    <br /><br />
                    Eseguiamo il ripristino e consolidamento di versanti in frana con vari interventi quali palificate in legname, terre rinforzate, idrosemina antierosione, chiodature, posa georeti, drenaggi ecc.
                </p>
                <SliderRealizzazioni images={Images} settings={Settings}/>
                </div>
            <Footer/>
        </div>
    )
}

export default Naturalistica