import React from 'react'
import Footer from '../../components/Footer/Footer'
import Navbar from '../../components/Navbar/Navbar'

import './Contatti_style.scss';

function Contatti() {

  var map = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d356.6956024228255!2d9.590643619883133!3d44.33944701197669!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12d4c108afeda9a9%3A0x23ae4b1c6bf323f4!2sSILVANO%20COSTRUZIONI%20S.R.L.!5e0!3m2!1sit!2sit!4v1657211671893!5m2!1sit!2sit";

  return (
    <div className='Contatti'>
        <Navbar/>

        <div className="Content">

          <h1> CONTATTACI </h1>

          <form target="_blank" action="https://formsubmit.co/costruzionisilvano@gmail.com" method="POST">
            <div class="form-group">
              <div class="form-row">
                <div class="col">
                  <input type="text" name="name" class="form-control" placeholder="Nome" required />
                </div>
                <div class="col">
                  <input type="email" name="email" class="form-control" placeholder="Email" required />
                </div>

                <div class="col">
                  <input type="hidden" name="telefono" class="form-control" placeholder="Telefono" required />
                </div>
              </div>
            </div>

            <div class="form-group">
              <textarea placeholder="Inserisci la richiesta" class="form-control" name="message" rows="10" required></textarea>
            </div>
            <button type="submit" class="btn btn-lg btn-dark btn-block">Invia la richiesta</button>
          </form>


          <h1> DOVE PUOI TROVARCI </h1>

          <iframe title='map' src={map} className='map' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

        <Footer/>
    </div>
  )
}

export default Contatti