import React from 'react'
import Footer from '../../components/Footer/Footer'
import Navbar from '../../components/Navbar/Navbar'

import './Chisiamo_style.scss';
import './Chisiamo_responsive.scss';

import SILVANO from '../../images/Home/Findmore/SILVANO.jpg';
import Certificazioni from '../../images/Chisiamo/Certificazioni.jpg'

function Chisiamo() {
  return (
    <div className='Chisiamo'>
        <Navbar/>
        <div className="Content">
            <h1> CHI SIAMO </h1>

            <p className='first'>

                <b> SILVANO COSTRUZIONI S.R.L. </b> nasce a San Pietro Vara (SP) nell’immediato dopoguerra per iniziativa di Luigi Silvano a cui si affianca quasi da subito il figlio Giovanni.
                <br/> <br/>
                L’attività principale svolta dall’Impresa Artigiana è rivolta prevalentemente alla costruzione di edifici civili ad uso abitazione.
                <br/> <br/>
                Nel 1973 la ditta passa al figlio Giovanni che continua l’attività intrapresa dal padre; negli anni novanta la specializzazione della ditta viene allargata anche alla costruzione di linee elettriche aeree (per conto di Enel).
                <br/> <br/>
                Col passare degli anni l’Impresa si evolve dotandosi progressivamente di nuova attrezzatura fino ad arrivare al 2010 con la costruzione a San Pietro Vara (SP) di un nuovo capannone che comprende un magazzino di circa 600 mq., uffici e piazzali esterni per lo stoccaggio di materiali ed attrezzatura.

            </p>

            <div className="Container">

                <span>
                <p className='second'>

                    Nel dicembre del 2010 la Ditta Individuale Silvano Giovanni viene trasformata nell’attuale <b>SILVANO COSTRUZIONI S.R.L</b>.
                    <br /><br />
                    Attualmente guida la società Giovanni con i figli Gabriele e Mirco; l’attività principale consiste nella costruzione di edifici residenziali/industriali, lavori stradali, costruzione acquedotti e fognature, opere idrauliche, opere di ingegneria naturalistica, costruzione parchi e campi da gioco
                    <br /><br />
                    Ultimamente ci stiamo specializzando in consolidamento di versanti con tecniche di ingegneria naturalistica, terre rinforzate, e gabbionate.
                    <br /><br />
                    L’azienda è dotata di personale altamente qualificato e di un vasto e moderno parco mezzi che consentono di effettuare tutte le lavorazioni con alta qualità costruttiva ed in piena autonomia.

                </p>
                </span>

                <span> <img src={SILVANO} alt="Silvano costruzioni" /> </span>

            </div>


            <div className="Certificazioni"> 

                <img src={Certificazioni} alt="Certificazioni" />

                <div className="description">
                    <p className='title'> 
                        L’Impresa SILVANO COSTRUZIONI S.R.L. è in possesso di attestazione SOA per l’esecuzione di lavori pubblici nelle seguenti categorie
                    </p>

                    <ul>
                        <li>OG1 – Edifici civili ed industriali</li>
                        <li>OG3 – Strade, autostrade, ponti, viadotti, ferrovie, metropolitane</li>
                        <li>OG6 – Acquedotti, gasdotti, oleodotti, opere di irrigazione e di evacuazione</li>
                        <li>OG8 – Opere fluviali, di difesa, di sistemazione idraulica e di bonifica</li>
                        <li>OG13 – Ingegneria naturlastica</li>
                        <li>OS21 - Fondazioni speciali - micro pali</li>


                    </ul>
                </div>

            </div>
        </div>

        <Footer/>
    </div>
  )
}

export default Chisiamo